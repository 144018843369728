import React from 'react';
import PhotoCarousel from '../../widget/carousel/carousel';
import FooterBanner from '../../widget/footer_banner/FooterBanner';
import Navbar from '../../widget/navbar/navbar';
import './gencive.css';
import '../../index.css';
import { Helmet } from 'react-helmet';

const Gencive = () => {
    return (
        <div className="App">
            <Helmet>
                <title>Gencive</title>
            </Helmet>
            <Navbar />
            <main>
                <section id="home">
                    <PhotoCarousel />
                </section>
                <div className="content">
                <section>
                    <h1>Soins des Gencives</h1>
                </section>

                <section>
                    <h2>Introduction</h2>
                    <p>Les maladies de gencives, ou maladies parodontales, sont à l’origine de 40% des extractions dentaires. Elles regroupent l’ensemble des troubles affectant les tissus autour des dents.</p>
                    <p>La conjonction de plusieurs facteurs est nécessaire au développement de ces pathologies. En effet, la qualité de la flore bucco-dentaire, les facteurs génétiques, le tabagisme, le diabète, le stress, la grossesse, les allergies, mais aussi les maladies cardiovasculaires, certaines infections virales, les malpositions dentaires, les obturations défaillantes, la présence de tartre, et bien sûr une mauvaise hygiène dentaire sont autant d’éléments à prendre en compte.</p>
                    <p>Les progrès importants de ces dernières années permettent un pronostic optimiste dans le traitement des maladies parodontales sous condition d’une hygiène buccale rigoureuse de la part du patient.</p>
                </section>

                <section>
                    <h2>Signes de Maladies Parodontales</h2>
                    <ul>
                        <li>Gencives rouges et gonflées</li>
                        <li>Saignements des gencives</li>
                        <li>Mauvaise haleine persistante</li>
                        <li>Des espaces commencent à apparaître entre les dents</li>
                        <li>Certaines dents se déplacent</li>
                        <li>Douleurs diffuses, agacement gingival, ou toute sensation de gêne</li>
                        <li>Les dents deviennent mobiles</li>
                    </ul>
                    <p>Ces signes ne sont pas toujours présents en même temps, tout dépend du stade de développement de la maladie.</p>
                </section>

                <section>
                    <h2>Traitement des Parodontopathies</h2>
                    <p>Tous les jours, un dépôt invisible se colle sur les dents. C’est la plaque dentaire, qui avec le temps se transforme et fixe le tartre.</p>
                    <h3>Évolution</h3>
                    <p>Les dents vont devenir mobiles avec le déchaussement, allant jusqu’à tomber. L’évolution est insidieuse, car elle se fait avec peu ou pas de douleur. Il y a peu de signes nets et apparents. L’évolution ne peut être stoppée sans un traitement adapté.</p>

                    <h3>Facteurs Aggravants</h3>
                    <ul>
                        <li>Stress, maladies générales, tabac, problèmes intestinaux.</li>
                        <li>Soins dentaires inadaptés, malposition dentaire, méconnaissance des techniques de soins adaptées.</li>
                    </ul>
                </section>

                <section>
                    <h2>Traitement des Maladies Parodontales</h2>
                    <p><strong>Votre rôle :</strong> contrôler la plaque dentaire au-dessus de la gencive (à l’aide de brosse à dents, brossettes et d’antiseptiques).</p>
                    <p><strong>Notre rôle :</strong> contrôler la plaque et le tartre en-dessous de la gencive.</p>
                    <p>Votre travail est aussi important que le nôtre.</p>

                    <h3>1er Temps : Technique de Soins et de Détartrage</h3>
                    <p>Lors d’une séance initiale de prophylaxie, notre équipe vous expliquera à l’aide d’un révélateur de plaque dentaire comment identifier les erreurs dans le brossage et éliminer efficacement la plaque dentaire qui, collée à la dent, favorise l’adhésion du tartre. Des recommandations personnalisées seront nécessaires afin de prendre soin des gencives.</p>

                    <h3>2e Temps : Bilan Parodontal</h3>
                    <p>Nous allons évaluer l’importance de votre maladie parodontale en réalisant un bilan radiographique complet accompagné d’un examen minutieux de toutes les poches parodontales afin d’en déterminer la profondeur et établir le traitement adéquat.</p>

                    <h3>3e Temps : Phase Chimique et/ou Phase Chirurgicale</h3>
                    <p>Nous nettoierons la gencive infectée (curetage) et éliminerons le tartre résiduel (surfaçage) sous anesthésie locale. Une légère sensibilité est possible pendant quelques jours lors du brossage.</p>
                    <p>À ce stade, le praticien détermine l’utilité d’un traitement chimique des poches par des irrigations qui permettent d’améliorer, voire de guérir les pathologies.</p>
                    <p>La réalisation d’une intervention chirurgicale (lambeau) n’est réalisée que lorsqu’il devient nécessaire d’avoir un accès direct aux racines. Cette intervention est indiquée lorsque les poches parodontales sont importantes. Les suites opératoires sont en général modérées; sinon, un traitement médicamenteux sera prescrit.</p>
                </section>
                </div>
            </main>
            <FooterBanner />
        </div>
    );
};

export default Gencive;
