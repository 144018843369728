import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './navbar.css';

const Navbar = () => {
  const navigate = useNavigate();

  const handleScroll = (event) => {
    event.preventDefault();
    const targetId = event.target.getAttribute('href').substring(1);

    if (window.location.pathname !== '/') {
      navigate('/'); // Redirige vers la page d'accueil
    }

    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    let lastScrollTop = 0;
    const header = document.querySelector('.header-bg');
    const nav = document.querySelector('nav');

    const handleScrollEvent = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop) {
        header.classList.add('hidden');
        nav.style.top = '0';
      } else {
        header.classList.remove('hidden');
        nav.style.top = '60px';
      }
      lastScrollTop = Math.max(scrollTop, 0);
    };

    window.addEventListener('scroll', handleScrollEvent);

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, []);

  return (
    <header>
      <div className="header-bg">
        <div className="credentitials">
          <img src="phone_white_svg.svg" alt="phone icon" />
          <p>
            <a href="tel:+33123456789" style={{ textDecoration: 'none', color: 'white' }}>
              01 34 87 79 28
            </a>
          </p>
        </div>
        <div className="credentitials">
          <img src="mail_with_svg.svg" alt="mail icon" />
          <p>
            <a
              href="mailto:info@dr-monteiro-croci.fr?subject=Demande%20d'information&body=Cher%20Docteur,"
              style={{ textDecoration: 'none', color: 'white' }}
            >
              info@dr-monteiro-croci.fr
            </a>
          </p>
        </div>
      </div>
      <nav>
        <div className="logo">
          <img src="logo.png" alt="Logo" />
          <span>Dr Monteiro-Croci</span>
        </div>
        <ul>
          <li><a href="#home" onClick={handleScroll}>Accueil</a></li>
          <li><a href="#about" onClick={handleScroll}>À propos</a></li>
          <li><a href="#services" onClick={handleScroll}>Services</a></li>
          <li><a href="#team" onClick={handleScroll}>Notre équipe</a></li>
          <li><a href="#contact" onClick={handleScroll}>Contact</a></li>
          <li><a href="#emergency" onClick={handleScroll}>Urgences</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;
