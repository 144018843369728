import React from 'react';
import PhotoCarousel from '../../widget/carousel/carousel';
import FooterBanner from '../../widget/footer_banner/FooterBanner';
import Navbar from '../../widget/navbar/navbar';
import './blanchiment.css';
import '../../index.css';
import { Helmet } from 'react-helmet';

const Blanchiment = () => {
    return (
        <div className="App">
            <Helmet>
                <title>Blanchiment</title>
            </Helmet>
            <Navbar />
            <main>
                <section id="home">
                    <PhotoCarousel />
                </section>

                <section>
                    <h1>Blanchiment</h1>
                </section>

                <section>
                    <h2>Éclaircissement Dentaire</h2>
                    <p>C’est ce qui permet d'éclaircir la teinte des dents. Le terme exact étant d’ailleurs « l’éclaircissement des dents ». Les techniques d’éclaircissement ne peuvent en aucun cas remplacer une bonne hygiène bucco-dentaire !</p>
                    <p>La couleur des dents est influencée par différents éléments : l'hérédité, la prise de certains médicaments pendant la période de formation des dents, le mode de vie (consommation de café, thé, tabac, vin…). C’est pourquoi, il est du devoir du patient de considérer cet état de fait et de prendre soin de ses dents !</p>
                    <p>L’éclaircissement est un procédé qui intervient a posteriori dans le but de corriger une coloration anormale incommodante ou afin d’avoir un sourire plus blanc pour des raisons esthétiques.</p>
                </section>

                <section>
                    <h2>Avant le Traitement</h2>
                    <p>Avant de commencer un éclaircissement, le Chirurgien-Dentiste effectuera un bilan bucco-dentaire global. Il vérifiera ainsi l’absence de contre-indications à ce type de traitement, telles que des caries, des mylolyses, ou des inflammations gingivales.</p>
                </section>

                <section>
                    <h2>Gouttières Ambulatoires</h2>
                    <p>Le chirurgien dentiste réalise une empreinte de la bouche, élabore des gouttières, qui seront appliquées pendant un certain temps (selon les produits utilisés et le résultat voulu). Le patient appliquera ce traitement chez lui de manière quotidienne et fréquentera le cabinet pour des visites de contrôle tout au long de la période du traitement.</p>
                </section>

                <section>
                    <h2>Séances au Fauteuil Dentaire</h2>
                    <p>Le praticien applique le matériau sur la surface des dents et projette une lumière pour stimuler le produit et le faire agir. La durée et le nombre de ces séances dépendent du résultat voulu au départ.</p>
                </section>

                <section>
                    <h2>Les Facettes</h2>
                    <p>La pose de facette est une technique qui permet de masquer les imperfections d’un sourire. Il s’agit d’un revêtement en céramique que l’on vient apposer sur la dent, permettant de dissimuler une dent lorsqu’elle est abimée, colorée, mal alignée ou qu’elle présente une anomalie au niveau de sa forme.</p>
                </section>
            </main>
            <FooterBanner />
        </div>
    );
};

export default Blanchiment;
