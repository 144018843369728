import React from "react";

function FooterBanner() {
  return (
    <footer style={styles.footer}>
      <div style={styles.container}>
        <div style={styles.columns}>
          <div style={styles.column}>
            <h3>Nos coordonnées</h3>
            <p>Vous pouvez joindre le secrétariat lundi de 09h40 à 16h45, mardi de 09h00 à 19h00, mercredi de 09h00 à 17h00, vendredi de 09h00 à 19h00 Sur rendez-vous</p>
            <p>47 rue Grand'cour</p>
            <p>78910 Civry-la-Forêt</p>
            <p>Téléphone : <a href="tel:+33134877928" style={styles.link}>01 34 87 79 28</a></p>
            <p>Email : <a href="mailto:docteur.monteiro-croci@orange.fr" style={styles.link}>docteur.monteiro-croci@orange.fr</a></p>
          </div>
          
          <div style={styles.column}>
            <h3>Nos conseils</h3>
            <p>Esthétique</p>
            <p>Le blanchiment</p>
            <p>Hygiène dentaire</p>
            <p>Les caries</p>
            <p>Les prothèses</p>
            <p>Soin des gencives</p>
          </div>

          <div style={styles.column}>
            <h3>Horaires d'ouverture</h3>
            <p>Lundi: 09:00 - 17:00</p>
            <p>Mardi: 09:00 - 17:00</p>
            <p>Mercredi: 09:00 - 17:00</p>
            <p>Jeudi: 09:00 - 17:00</p>
            <p>Vendredi: 09:00 - 17:00</p>
          </div>
        </div>
      </div>
      <div style={styles.container}>
      <p>&copy; {new Date().getFullYear()} by <a href="https://louis-portfolio.domotique-croci.fr/" target="_blank" rel="noopener noreferrer" className="footer-link">Louis Croci</a>. All rights reserved.</p>
      </div>
    </footer>
  );
}

const styles = {
  footer: {
    backgroundColor: "#282c34",
    padding: "20px",
    bottom: 0,
    width: "100%",
    textAlign: "center",
    color: "white",
  },
  container: {
    maxWidth: "1200px",
    margin: "0 auto",
  },
  columns: {
    display: "flex",
    justifyContent: "space-between",
  },
  column: {
    flex: "1",
    padding: "0 10px",
  },
  link: {
    color: "white",
    textDecoration: "none",
  }
};

export default FooterBanner;
