import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home/home';
import Gencive from './pages/gencive/gencive';
import Blanchiment from './pages/blanchiment/blanchiment';
import Caries from './pages/caries/caries';
import Esthetique from './pages/esthetique/esthetique';
import Hygiene from './pages/hygiene/hygiene';
import Implant from './pages/implant/implant';
import NotFound from './pages/404/404';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gencive" element={<Gencive />} />
          <Route path="/blanchiment" element={<Blanchiment />} />
          <Route path="/caries" element={<Caries />} />
          <Route path="/esthetique" element={<Esthetique />} />
          <Route path="/hygiene" element={<Hygiene />} />
          <Route path="/implant" element={<Implant />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
