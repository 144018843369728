import React from 'react';
import PhotoCarousel from '../../widget/carousel/carousel';
import FooterBanner from '../../widget/footer_banner/FooterBanner';
import Navbar from '../../widget/navbar/navbar';
import './esthetique.css';
import '../../index.css';
import { Helmet } from 'react-helmet';

const Esthetique = () => {
    return (
        <div className="App">
            <Helmet>
                <title>Esthetique</title>
            </Helmet>
            <Navbar />
            <main>
                <section id="home">
                    <PhotoCarousel />
                </section>
                <div className="content">
                <section>
                    <h1>Esthétique dentaire</h1>
                </section>
                <section>
                    <h2>Introduction</h2>
                    <p>L’esthétique dentaire a longtemps été assimilée à la restitution de la fonction masticatoire et au remplacement des dents absentes. Cette approche se justifiait encore il y a 10 ou 20 ans.</p>
                    <p>Cependant, l’arrivée de nouvelles techniques de traitement, de matériaux de reconstitution nouveaux, et de formations pointues dans le domaine de la dentisterie esthétique globale a permis une évolution en la matière. Ainsi, les praticiens français disposent de véritables solutions de réhabilitation du sourire pour les patients exprimant une insatisfaction liée à une dissymétrie disgracieuse, à des décolorations ou une teinte originale perdue, ou encore une déformation d’une ou de plusieurs dents.</p>
                </section>

                <section>
                    <h2>Solutions Courantes</h2>
                    <p>Le praticien pratiquant cette discipline fera appel à différentes solutions. Les plus courantes sont :</p>
                    <ul>
                        <li><strong>Facettes :</strong> Apposées sur les dents existantes, elles permettent de modifier la forme et d’harmoniser le sourire.</li>
                        <li><strong>Blanchiment :</strong> L’éclaircissement agit sur la teinte et produit un effet d’illumination du visage grâce à un sourire redevenu éclatant.</li>
                        <li><strong>Implants :</strong> Leur fonction de racine artificielle permet un remplacement unitaire de la dent sans abimer les dents adjacentes.</li>
                        <li><strong>Orthodontie :</strong> L’orthodontie adulte aide à la reconstruction du sourire par le réalignement des dents.</li>
                    </ul>
                    <p>D’autres techniques de restauration partielles de la dent existent également, afin d’éviter la couronne prothétique. Ces solutions sont nombreuses : composites stratifiés, inlays ou onlays réalisés en résine ou céramique en technique directe (au cabinet) ou indirecte (au laboratoire)…</p>
                </section>

                <section>
                    <h2>Conclusion</h2>
                    <p>Aujourd’hui, le patient est assuré de pouvoir trouver une réponse adaptée à l’ensemble de ses problèmes dentaires tant fonctionnels qu’esthétiques.</p>
                    <p>Pour en savoir plus, vous pouvez consulter les articles suivants :</p>
                    <p><em>Avec l'aimable autorisation du Dr Jean-Christophe Paris de l'académie du sourire, et de Mimesis.</em></p>
                </section>
                </div>
            </main>
            <FooterBanner />
        </div>
    );
};

export default Esthetique;
