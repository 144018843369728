import React, { useEffect } from 'react';
import PhotoCarousel from '../../widget/carousel/carousel';
import MapComponent from '../../widget/map/MapComponent';
import FooterBanner from "../../widget/footer_banner/FooterBanner";
import ContactForm from "../../widget/contact_form/ContactForm"; 
import Navbar from "../../widget/navbar/navbar";
import './home.css';
import '../../index.css';
import { Helmet } from 'react-helmet';

const Home = () => {
    useEffect(() => {
        const element = document.getElementById('years');
        const targetYear = +element.getAttribute('data-target');
        const currentYear = new Date().getFullYear();
        const yearsRemaining = currentYear - targetYear;

        let count = 0;
        const increment = Math.ceil(yearsRemaining / 200); // Ajustez la vitesse de l'animation

        const updateCount = () => {
        count += increment;
        if (count < yearsRemaining) {
            element.textContent = count;
            setTimeout(() => requestAnimationFrame(updateCount), 30);
        } else {
            element.textContent = yearsRemaining; // Assurez-vous que le nombre final est correct
        }
        };

        const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
            updateCount();
            observer.unobserve(element); // Arrêtez d'observer après l'animation
            }
        });
        });

        // Nettoyage de l'observer à la désinstallation
        return () => {
            observer.disconnect();
        };
    }, []); // L'effet s'exécute une seule fois après le montage

        return (
            <div className="App">
                <Helmet>
                    <title>Acceuil</title>
                </Helmet>
                <Navbar />
            <main>
            <section id="home">
                <PhotoCarousel />
            </section>

            <section id="about">
                <div className="content">
                <h1>À propos</h1>
                <p>Implanté à Civry-la-Forêt, le cabinet dentaire du docteur Stéphanie Monteiro-Croci s'engage à délivrer des soins modernes, de qualité, et à fournir à tout nouveau patient un bilan dentaire complet. Notre équipe est attachée à optimiser la santé bucco-dentaire de ses patients. Notre équipe est spécialisée dans la prothèse implantaire et l'esthétique du sourire, et formée en orthodontie et orthopédie interceptive.</p>
                </div>
            </section>

            <section id="services">
                <div className="parallax">
                <div className="title">
                    <h1>Nos services</h1>
                </div>
                <div className="services-row">
                    <div className="service">
                        <h3>Soins des gencives</h3>
                        <p>Les maladies de gencives, ou maladies parodontales, sont à l’origine de 40% des extractions dentaires. Elles regroupent l’ensemble des troubles affectant les tissus autour des dents.</p>
                        <button onClick={() => window.location.href = '/gencive'}>En savoir plus</button>
                    </div>
                    <div className="service">
                        <h3>Hygiène dentaire</h3>
                        <p>Aujourd’hui, les seuls moyens qui permettent de garder la bouche en bonne santé sont des moyens mécaniques. Il s’agit d’une part du brossage des dents, et du nettoyage interdentaire. Et d’autre part, de séances de maintenance prophylactique au sein d’un cabinet dentaire.</p>
                        <button onClick={() => window.location.href = '/hygiene'}>En savoir plus</button>
                    </div>
                </div>
                <div className="services-row">
                    <div className="service">
                        <h3>Blanchiment</h3>
                        <p>C’est ce qui permet d'éclaircir la teinte des dents. Le terme exact étant d’ailleurs « l’éclaircissement des dents ».</p>
                        <button onClick={() => window.location.href = '/blanchiment'}>En savoir plus</button>
                    </div>
                    <div className="service">
                        <h3>Les caries</h3>
                        <p>La carie est une maladie infectieuse de la dent affectant l’émail et la dentine. Son apparition est due au développement des bactéries dans la bouche</p>
                        <button onClick={() => window.location.href = '/caries'}>En savoir plus</button>
                    </div>
                </div>
                <div className="services-row">
                    <div className="service">
                        <h3>Esthétique</h3>
                        <p>L’esthétique dentaire a longtemps été assimilée à la restitution de la fonction masticatoire et au remplacement des dents absentes. Cette approche se justifiait encore il y a 10 ou 20 ans.</p>
                        <button onClick={() => window.location.href = '/esthetique'}>En savoir plus</button>
                    </div>
                    <div className="service">
                        <h3>Les implants</h3>
                        <p>Les techniques implantaires ont pour but de préserver les dents saines adjacentes aux dents absentes qu’il faut remplacer. C’est aussi la bonne solution pour servir de support à une prothèse dite amovible en la rendant fixe.</p>
                        <button onClick={() => window.location.href = '/implant'}>En savoir plus</button>
                    </div>
                </div>
                </div>
            </section>

            <section id="team">
                <div className="statistic">
                <div className="data">
                    <h1 id="years" data-target="1999">0</h1>
                    <p>Années d'expérience</p>
                </div>
                <div className="data">
                    <h1>1</h1>
                    <p>Denstiste</p>
                </div>
                <div className="data">
                    <h1>1</h1>
                    <p>Assistant</p>
                </div>
                </div>
                <div className="content">
                <img src="pp-docteur.webp" alt="Notre équipe" className='photo'/>
                <h2>Dr. Monteiro-Croci</h2>
                <p>Chirurgien dentiste</p>
                </div>
                <div className="parallax"></div>
            </section>

            <section id="contact">
                <div className="content">
                <h1>Contactez-nous</h1>
                <div className="contacts">
                    <div className="contact">
                    <h3>Notre adresse</h3>
                    <p>47 rue de la grande cour</p>
                    <p>78910 Civry-la-Forêt, France</p>
                    <p>
                        <a
                        href="mailto:info@dr-monteiro-croci.fr?subject=Demande%20d'information&body=Cher%20Docteur," 
                        style={{ textDecoration: "none", color: "black" }}
                        >
                        Email: info@dr-monteiro-croci.fr
                        </a>
                    </p>
                    <p>
                        <a 
                        href="tel:+33123456789" 
                        style={{ textDecoration: "none", color: "black" }}
                        >
                        Téléphone: 01 34 87 79 28
                        </a>
                    </p>
                    </div>
                    <div className="separator"></div> {/* Séparateur ici */}
                    <div className="contact">
                    <h3>Horaires d'ouverture</h3>
                    <p>Lundi - Vendredi</p>
                    <p>9h00 - 17h00</p>
                    <button>Contacter nous</button>
                    </div>
                </div>
                <MapComponent />
                <ContactForm />
                </div>
                <div className="parallax"></div>
            </section>

            <section id="emergency">
                <div className="content">
                <h1>En cas d'urgences</h1>
                <h2>SERVICE DE GARDE</h2>
                <p>Le Conseil de l'Ordre Départemental des Chirurgiens-Dentistes organise un service de garde pour les dimanches et jours fériés, afin que tout patient puisse trouver un Chirurgien-Dentiste disponible à tout moment en cas de problèmes dentaires.</p><br />
                <p>La plupart des douleurs dentaires peuvent être traitées par votre Chirurgien-Dentiste le lendemain ou le surlendemain, mais il arrive qu'un abcès ou une dent arrachée lors d'un accident doivent être soignés en urgence.</p><br />
                <p>En effet, il existe des pathologies dentaires dont la douleur peut empêcher de dormir ou nécessiter une intervention chirurgicale rapide pour éviter de perdre une dent.</p><br />
                <p>Les services de santé se sont organisés pour assurer des gardes par les Chirurgiens-Dentistes le soir, le week-end et les jours fériés. Si c'est le cas dans votre département, les numéros à contacter en cas d'urgence dentaire sont généralement transmis aux commissariats chaque mois.</p><br />
                <p>Si votre département ne dispose pas de service de garde, vous pourrez contacter l’hôpital le plus proche qui assurera votre prise en charge.</p><br />
                <p>En cas de traumatisme dentaire (suite à une chute par exemple), nous vous invitons à prendre contact au plus tôt avec le cabinet, ou avec l'hôpital le plus proche (service odontologie ou stomatologie et chirurgie maxillo-faciale).</p>
                <button onClick={() => window.location.href = "https://www.oncd-78.fr/chirurgiens-dentistes-de-garde/"}>
                    Service de garde
                </button>
                </div>
            </section>
            </main>
            <FooterBanner />
            </div>
        );

}

export default Home;
