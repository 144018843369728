import React from 'react';
import PhotoCarousel from '../../widget/carousel/carousel';
import FooterBanner from '../../widget/footer_banner/FooterBanner';
import Navbar from '../../widget/navbar/navbar';
import './caries.css';
import '../../index.css';
import { Helmet } from 'react-helmet';

const Caries = () => {
    return (
        <div className="App">
            <Helmet>
                <title>Caries</title>
            </Helmet>
            <Navbar />
            <main>
                <section id="home">
                    <PhotoCarousel />
                </section>
                <div className="content">
                <section>
                    <h1>La Carie</h1>
                </section>
                <section>
                    <h2>Qu'est-ce que la Carie ?</h2>
                    <p>La carie est une maladie infectieuse de la dent affectant l’émail et la dentine. Son apparition est due au développement des bactéries dans la bouche.</p>
                    <p>En effet, les bactéries normalement présentes dans la bouche et dans la plaque dentaire peuvent devenir nuisibles lorsque l’équilibre du biofilm est compromis. Les bactéries transforment alors certains aliments en substances acides qui attaquent l’émail des dents, la dentine - sous l’émail - et enfin les nerfs.</p>
                    <p>Lorsque les caries s’accumulent à un endroit (notamment entre les dents, sous d’anciens plombages ou sous des couronnes mal adaptées), elles deviennent virulentes et entraînent la déminéralisation de votre dent. La carie ne concerne pas seulement les dents vivantes, par conséquent elle n’est pas toujours douloureuse.</p>
                </section>

                <section>
                    <h2>Les Signes de la Maladie</h2>
                    <h3>Début de la Maladie</h3>
                    <p>Les signes ne sont pas toujours visibles, mais le risque est bien réel.</p>

                    <h3>Premiers Signes Visibles</h3>
                    <ul>
                        <li>Gencives rouges et saignements</li>
                        <li>Dépôts alimentaires visibles à l’œil nu et présence de bactéries</li>
                        <li>Premières tâches blanches de déminéralisation à la surface de l’émail</li>
                    </ul>

                    <h3>Évolution de la Maladie</h3>
                    <p>La carie risque de prendre une ampleur considérable pouvant atteindre le nerf de la dent.</p>
                </section>

                <section>
                    <h2>Facteurs Favorisant cette Maladie</h2>
                    <ul>
                        <li>Manque d’efficacité de la salive dans la lutte anti-bactérienne</li>
                        <li>Fragilité de l’émail</li>
                        <li>Anatomie dentaire</li>
                        <li>Malpositions dentaires</li>
                        <li>Couronnes mal adaptées</li>
                        <li>Manque de fluor</li>
                    </ul>
                </section>

                <section>
                    <h2>Facteurs Aggravants</h2>
                    <ul>
                        <li>Les habitudes alimentaires</li>
                        <li>État général</li>
                        <li>Prise de certains médicaments</li>
                        <li>La grossesse</li>
                    </ul>
                </section>

                <section>
                    <h2>Traitement des Caries</h2>
                    <p>Le traitement vise à stopper l’évolution de la maladie, en agissant sur le facteur initial (la plaque microbienne), et à éliminer les tissus abimés. Enfin, il permet de reconstruire les dents de manière à retrouver leur forme initiale.</p>
                    <p>Il existe différentes reconstitutions :</p>
                    <h3>Composites (pour des caries débutantes)</h3>
                    <p>Pour les petites caries, les composites (matériaux apparentés aux résines) conviennent parfaitement. Ils sont esthétiques mais transitoires (solidité et herméticité faibles). Ils doivent donc être changés régulièrement et offrent l’avantage de ne pas comporter de métal.</p>

                    <h3>Inlays et Onlays (pour des caries plus volumineuses)</h3>
                    <p>Sur ce type de carie, les amalgames et les composites ne peuvent pas offrir une garantie de durée. En effet, ces matériaux présentent des risques de fissure sur le long terme. Le joint avec la dent s’agrandit, devenant perméable aux bactéries, ce qui engendre des reprises de caries. De plus, ils ne peuvent protéger les parois fragiles, nécessitant des refontes régulières.</p>
                    <p>L’inlay, présentant une morphologie beaucoup plus précise, préserve mieux les parois résiduelles et permet de maintenir la dent vivante. Lorsqu’il est fait en céramique ou en résine, il se confond avec la dent naturelle.</p>
                </section>
                </div>
            </main>
            <FooterBanner />
        </div>
    );
};

export default Caries;
