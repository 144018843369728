import React from 'react';
import PhotoCarousel from '../../widget/carousel/carousel';
import FooterBanner from '../../widget/footer_banner/FooterBanner';
import Navbar from '../../widget/navbar/navbar';
import './hygiene.css';
import '../../index.css';
import { Helmet } from 'react-helmet';

const Hygiene = () => {
    return (
        <div className="App">
            <Helmet>
                <title>Hygiene</title>
            </Helmet>
            <Navbar />
            <main>
                <section id="home">
                    <PhotoCarousel />
                </section>

                <div className="content">
                <section>
                    <h1>Hygiène Dentaire</h1>
                </section>

                <section>
                    <h2>Importance de l'Hygiène Dentaire</h2>
                    <p>Aujourd’hui, les seuls moyens qui permettent de garder la bouche en bonne santé sont des moyens mécaniques. Il s’agit d’une part du brossage des dents, et du nettoyage interdentaire. D’autre part, des séances de maintenance prophylactique au sein d’un cabinet dentaire sont également essentielles.</p>
                </section>

                <section>
                    <h2>Combien de fois par jour ?</h2>
                    <p>Il est préférable de se brosser les dents après chaque repas. En effet, l’association de certains résidus alimentaires et des bactéries présentes en bouche favorise l’apparition des caries. C’est pourquoi, il est très important de veiller à ne pas garder de débris alimentaires sur les surfaces dentaires en se brossant les dents régulièrement.</p>
                </section>

                <section>
                    <h2>Choix de la Brosse à Dents</h2>
                    <p>Le brossage peut s’effectuer avec une brosse à dents manuelle ou électrique. Idéalement, la brosse à dents doit être à poils souples, avec une tête réduite pour accéder à toutes les dents, même les plus au fond. Celles du fond, étant par définition les plus difficiles d’accès, méritent une attention particulière.</p>
                </section>

                <section>
                    <h2>Choix du Dentifrice</h2>
                    <p>Il existe sur le marché des centaines de dentifrices, et les patients sont souvent perdus dans leur choix. En effet, le dentifrice est un médicament et doit être prescrit en fonction de l’état bucco-dentaire du patient et de son niveau de risque à développer des maladies carieuses et/ou des maladies parodontales. Demandez donc conseil à votre pharmacien qui saura vous prescrire le dentifrice adapté à votre bouche.</p>
                </section>

                <section>
                    <h2>Techniques de Brossage</h2>
                    <p>La meilleure technique de brossage est celle qui permet le maximum d’élimination de plaque dentaire et le minimum de traumatisme. Il existe une multitude de techniques de brossage, et il est fortement recommandé de consulter un Chirurgien-Dentiste pour savoir quelle technique vous convient le mieux.</p>
                    <p>Le brossage doit être adapté en fonction de la nature dentaire, de l’alignement des dents, du type de gencives (fines, normales, ou épaisses), ainsi que des différentes reconstitutions présentes en bouche.</p>
                    <p>Tout brossage doit être accompagné par des techniques de nettoyage interdentaire, comme le fil dentaire et les brossettes, ainsi que des moyens chimiques comme les bains de bouche.</p>
                </section>
                </div>
            </main>
            <FooterBanner />
        </div>
    );
};

export default Hygiene;
