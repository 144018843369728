import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './MapComponent.css'; // Importer le fichier CSS

// Initialiser l'icône par défaut
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const MapComponent = () => {
  const position = [48.867122639541726, 1.6188546506364636]; // Coordonnées

  return (
    <MapContainer center={position} zoom={13} scrollWheelZoom={false} className="map-container">
      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
      />
      <Marker position={position}>
        <Popup className="map-popup">
          Cabinet dentaire du Dr. Stéphanie Monteiro-Croci
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default MapComponent;
