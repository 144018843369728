import React from 'react';
import PhotoCarousel from '../../widget/carousel/carousel';
import FooterBanner from '../../widget/footer_banner/FooterBanner';
import Navbar from '../../widget/navbar/navbar';
import './implant.css';
import '../../index.css';
import { Helmet } from 'react-helmet';

const Implant = () => {
    return (
        <div className="App">
            <Helmet>
                <title>Implant</title>
            </Helmet>
            <Navbar />
            <main>
                <section id="home">
                    <PhotoCarousel />
                </section>

                <div className="content">
                <section>
                    <h1>Prothèses dentaires</h1>
                </section>
                <section>
                    <h2>Introduction</h2>
                    <p>L’acte prothétique, ou la mise en place de prothèses dentaires, permet de remplacer des dents absentes ou de reconstituer des dents délabrées.</p>
                    <p>Durant les 3 dernières décennies, les solutions prothétiques se sont fortement développées, apportant des réponses esthétiques et fonctionnelles de grande qualité. Il existe plusieurs types de prothèses en fonction du traumatisme à réparer. Le praticien, lors de son diagnostic, tiendra compte de l'ensemble de ces solutions afin de vous proposer un plan de traitement adapté à votre cas.</p>
                </section>

                <section>
                    <h2>L’Inlay/Onlay</h2>
                    <p>L’inlay est utilisé pour obturer une petite ou moyenne cavité. Outre ses performances esthétiques, il limite la taille de la dent à la partie cariée grâce à des techniques de collage très hermétiques. Il apporte une véritable réponse aux problèmes d’esthétique, d’étanchéité et de durée de vie des composites (résine) ou amalgames (plombages au mercure).</p>
                    <p>L’onlay est recommandé lorsque la dent est plus abimée sur sa partie externe. Il permet souvent d’éviter la pose d’une couronne qui, outre son prix plus élevé, nécessite beaucoup plus de taille de l’émail et donc une perte de matière saine inutile.</p>
                </section>

                <section>
                    <h2>Les Reconstitutions Corono-Radiculaires</h2>
                    <p>Elles correspondent à l’Inlay-core, appelé également tenon ou faux moignon. C’est une reconstitution coulée nécessaire lorsque la dent est très délabrée afin de réaliser une couronne prothétique. Sous cette même appellation, on trouve les reconstitutions de moignon qui sont faites avec des tenons radiculaires vissés ou scellés dans le ou les canaux de la dent délabrée.</p>
                </section>

                <section>
                    <h2>Les Couronnes Transitoires</h2>
                    <p>Ces prothèses provisoires sont absolument indispensables de nos jours. Elles sont essentielles pour :</p>
                    <ul>
                        <li>Favoriser la cicatrisation de la gencive autour de la dent préparée.</li>
                        <li>Protéger la dent préparée et éviter son délabrement.</li>
                        <li>Isoler le milieu salivaire des tissus dentaires meurtris.</li>
                        <li>Supprimer les chocs thermiques au niveau des dents pulpées.</li>
                        <li>Eviter l’égression (les mouvements) des dents adjacentes.</li>
                    </ul>
                </section>

                <section>
                    <h2>Les Couronnes Définies</h2>
                    <p>Il s’agit de prothèses qui coiffent totalement la dent. Autrefois entièrement en métal ou en or, elles sont aujourd’hui en résine ou en céramique et fixées sur des chapes (pièces métalliques faites en laboratoire) en métal précieux ou non précieux.</p>
                    <p>L’arrivée de l’implantologie et des toutes nouvelles techniques de réhabilitation esthétique auraient dû sonner le glas de certaines de ces prothèses. La nomenclature générale des actes professionnels - datant d’après-guerre - et le système de prise en charge par la sécurité sociale encouragent le maintien de ces prothèses désuètes.</p>
                    <p>En effet, l’esthétique n’entrant pas en compte dans le système de prise en charge français, ces prothèses sont conservées pour des questions de coûts, malgré le fait que la population, lors d’une enquête récente, exprimait à 66% sa volonté de voir l’aspect esthétique mieux considéré lors de traitements prothétiques.</p>
                    <p>Ces dernières années, de nouvelles couronnes sans métal ont fait leur apparition (ex : Zircone). La suppression du métal résout les problèmes d’allergie et permet d’obtenir un meilleur rendu esthétique.</p>
                </section>

                <section>
                    <h2>Les Couronnes sur Dent Dévitalisée</h2>
                    <p>C’est la solution utilisée lorsque la dent est délabrée par une carie très profonde - c'est-à-dire que la carie a atteint le nerf de la dent. Dans ces cas-là, la dent doit être dévitalisée. Une fois dévitalisée, la dent n'est plus nourrie, le flux sanguin entre la dent et la racine est interrompu. La dent devient alors très fragile et peut casser si l'on mange quelque chose de dur. Lorsqu'elle casse, elle se brise sous la gencive, et il devient alors trop tard pour la sauver.</p>
                </section>

                <section>
                    <h2>Le Bridge</h2>
                    <p>Le bridge est une prothèse permettant le remplacement de dents absentes en prenant appui sur une ou plusieurs dents restantes. C’est une solution de seconde intention lorsque la réalisation des implants dentaires est contre-indiquée, ou lorsque les dents adjacentes présentent des caries profondes et nécessitent des couronnes.</p>
                </section>
                </div>
            </main>
            <FooterBanner />
        </div>
    );
};

export default Implant;
