// Importation des styles de la bibliothèque
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';

function PhotoCarousel_acceuil() {
  return (
    <div style={{ maxWidth: "100%", margin: "0" }}>
      <Carousel 
        showArrows={false} 
        autoPlay={true} 
        infiniteLoop={true}
        showThumbs={false}
      >
        <div>
          <img src="carousel-acceuil/img1.webp" alt="Imagessss 1" />
        </div>
        <div>
          <img src="carousel-acceuil/img2.webp" alt="Imagessssss 2" />
        </div>
        <div>
          <img src="carousel-acceuil/img3.webp" alt="Imagessssssssss 3" />
        </div>
      </Carousel>
    </div>
  );
}

export default PhotoCarousel_acceuil;
