import './ContactForm.css'; // Importer le fichier CSS

function ContactForm() {
    return (
        <div className="contact-form">
            <h2>Contactez-nous</h2>
            <form action="http://localhost:3001/send" method="POST">
                <div className="row">
                    <div className="champ">
                        <label htmlFor="name">Nom :</label>
                        <input type="text" name="name" id="name" className="half-width" required />
                    </div>
                    <div className="champ">
                        <label htmlFor="surname">Prénom :</label>
                        <input type="text" name="surname" id="surname" className="half-width" required />
                    </div>
                </div>

                <div className="row">
                    <div className="champ">
                        <label htmlFor="email">Email :</label>
                        <input type="email" name="email" id="email" className="half-width" required />
                    </div>
                    <div className="champ">
                        <label htmlFor="objet">Objet :</label>
                        <input type="text" name="objet" id="objet" className="half-width" required />
                    </div>
                </div>

                <div className="row">
                    <div className="champ">
                        <label htmlFor="message">Message :</label>
                        <textarea name="message" id="message" required></textarea>
                    </div>
                </div>

                <button type="submit">Envoyer</button>
            </form>
        </div>
    );
}
    

export default ContactForm;
